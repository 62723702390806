import { useEffect, useMemo, useState } from 'react';
import {
    Card,
    EventProgress,
    Media,
    PrewhitelistCard,
    Typography,
} from '@decub8/ui';
import { commify, formatEther } from '@ethersproject/units';
import dayjs from 'dayjs';

import {
    isAmaEventFinished,
    isAmaEventLive,
    isWithin60SecondsBefore,
} from '@src/components/HomepageComponents/MainEventCardContainer/utils';
import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { useTokenBalance } from '@src/hooks';
import { useEventDates } from '@src/hooks/useEventTimes';
import { EventType } from '@src/ts/constants';
import { ProjectEvent as IProjectEvent } from '@src/ts/interfaces';
import { parseBalance } from '@src/utils/web3';

import { useProjectContext } from '../context';

import { CountdownCardEventWidget } from './CountdownCard';
import { EventCompleteSummary } from './EventCompleteSummary';
import { EventHeader } from './EventHeader';
import { useCurrentEventStage } from './hooks';
import { ExpandableStages } from './Stages';
import { getStages, isHardcapMet } from './utils';

export const ProjectEvent: React.FC<{
    project_id: string;
    scrollToLearnToEarn: () => void;
    event: IProjectEvent;
}> = ({ event, project_id, scrollToLearnToEarn }) => {
    const [is_mounted, setIsMounted] = useState(false);
    const now = dayjs();

    const {
        data_loading,
        ga_registered,
        crowdfunding,
        num_whitelisted,
        getWhitelistSummaryForUser,
    } = useProjectContext();

    const { hardcap = '0', total_raised = '0' } = crowdfunding || {};

    const {
        ama_event,
        chainId,
        type,
        start_date,
        event_details,
        marketing_event,
        contract,
    } = event;

    const { symbol: payment_symbol, decimals: payment_decimals } =
        CONTRACT.PaymentToken[chainId];

    const hardcap_met = isHardcapMet(payment_decimals, hardcap, total_raised);

    const event_chain = chainId || DEFAULT_CHAIN_ID;

    const { address } = CONTRACT.PaymentToken[event_chain];

    const { min_tier = 0 } = event_details || {};

    const payment_balance = useTokenBalance(address, event_chain);

    const active_stage = useCurrentEventStage(min_tier);

    const start = dayjs(Number(start_date) * 1000);

    const { cutoff, has_reached_cutoff, is_now_cutoff_period, event_end_date } =
        useEventDates(start, event_details?.durations, event.type);

    const is_crowdfunding = type === EventType.Crowdfunding;

    const is_event_over = is_crowdfunding
        ? now.isAfter(event_end_date) || hardcap_met
        : now.isAfter(event_end_date);

    const has_crowdfunding_started = now.isAfter(start);

    const has_contract = contract?.abi && contract?.address;

    const whitelist_open =
        has_contract && now.isBefore(start) && !is_now_cutoff_period;

    const should_turn_red_start = isWithin60SecondsBefore(start);

    const should_turn_red_cutoff = isWithin60SecondsBefore(cutoff);

    // countdown component takes a unix timestamp
    const countdown_target_date = whitelist_open
        ? cutoff.unix().toString()
        : event.start_date;

    const loaded = useMemo(
        () =>
            [EventType.TokenClaim].includes(type) ||
            !event.contract?.address ||
            !event.contract?.abi ||
            !!crowdfunding,
        [type, event.contract, crowdfunding],
    );

    const options = useMemo(() => {
        const stages = getStages(
            project_id,
            event,
            scrollToLearnToEarn,
            ga_registered,
            payment_balance,
        );
        return is_event_over && is_crowdfunding
            ? [stages[stages.length - 1]]
            : stages;
    }, [
        project_id,
        event,
        scrollToLearnToEarn,
        ga_registered,
        payment_balance,
        is_event_over,
        is_crowdfunding,
    ]);

    // TODO: not sure if all this is needed
    const has_ama_event =
        ama_event &&
        ama_event?.type &&
        ama_event?.title &&
        ama_event?.link &&
        !!ama_event?.start_date &&
        !!ama_event?.end_date;

    const has_marketing_event =
        marketing_event &&
        marketing_event?.title &&
        marketing_event?.link &&
        marketing_event?.image;

    const ama_data = has_ama_event
        ? {
              title: ama_event?.title,
              date: dayjs(Number(ama_event?.start_date) * 1000).format(
                  'D MMMM, H:mm [UTC]',
              ),
              youtube_url: ama_event?.link,
              show_ama_live: isAmaEventLive(ama_event),
              is_complete: isAmaEventFinished(ama_event),
          }
        : undefined;

    // use 1 if hardcap === 0 as 0/0 === NaN
    const denom = Number(formatEther(hardcap)) || 1;
    const percent_filled = Number(
        ((Number(formatEther(total_raised)) / denom) * 100).toFixed(1),
    );

    const total_raised_display_value = `${parseBalance(
        total_raised,
        payment_decimals,
        2,
        true,
    )} ${payment_symbol}`;

    const raised_stats = {
        total: total_raised_display_value,
        target: `${parseBalance(
            hardcap,
            payment_decimals,
            2,
            true,
        )} ${payment_symbol}`,
        percentage: percent_filled,
    };

    useEffect(() => {
        setIsMounted(true);
    }, []);

    // mock the stages for testing - use mock_stage instead of active_stage as <ExpandableStages/> prop
    // const [mock_stage, setMockStage] = useState<number>(0);
    // useEffect(() => {
    //     const mockStages = async () => {
    //         for (let i = 0; i < options.length; i++) {
    //             setMockStage(i);
    //             await new Promise((resolve) => setTimeout(resolve, 5000));
    //         }
    //     };
    //     mockStages();
    // }, [options]);

    // if active stage is ga, get the user allocation again, as that has changed during the snapshot stage
    useEffect(() => {
        if (active_stage === 2) {
            getWhitelistSummaryForUser();
        }
    }, [active_stage]);

    // TODO: countdown needs to turn red for 60 seconds before cutoff also
    if (!is_mounted) return null;

    return (
        <Card>
            <EventHeader event={event} />
            {!loaded || data_loading ? (
                <div className="justify-center flex items-center h-60">
                    <Media variant="loader" className="w-16 h-16" />
                </div>
            ) : (
                <div className="px-5 py-8">
                    {has_crowdfunding_started &&
                        is_crowdfunding &&
                        !is_event_over && (
                            <EventProgress
                                raised_stats={raised_stats}
                                is_mobile={false}
                            />
                        )}
                    {has_ama_event &&
                        !whitelist_open &&
                        is_crowdfunding &&
                        !has_crowdfunding_started && (
                            <PrewhitelistCard
                                ama_data={ama_data}
                                is_mobile={false}
                            />
                        )}
                    {has_marketing_event &&
                        !whitelist_open &&
                        !has_ama_event && (
                            <div>
                                <Typography variant="secondary">
                                    {marketing_event.title}
                                </Typography>
                                <a
                                    href={marketing_event.link}
                                    key={JSON.stringify(marketing_event.link)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className={`rounded-lg mt-5 mx-auto low-border`}
                                        src={marketing_event.image}
                                        alt="marketing-event-image"
                                    />
                                </a>
                            </div>
                        )}

                    {is_event_over && is_crowdfunding && (
                        <EventCompleteSummary
                            whitelisted_participants={commify(num_whitelisted)}
                            amount_raised={`${parseBalance(
                                total_raised,
                                payment_decimals,
                                0,
                                true,
                            )} ${payment_symbol}`}
                            percentage_raised={`${percent_filled}%`}
                        />
                    )}
                    {(whitelist_open || is_now_cutoff_period) &&
                        is_crowdfunding && (
                            <CountdownCardEventWidget
                                whitelist_open={whitelist_open}
                                target_date={countdown_target_date}
                                has_accent_countdown={
                                    should_turn_red_start ||
                                    should_turn_red_cutoff
                                }
                            />
                        )}
                    {is_crowdfunding && (
                        <div className="bg-surface-level-three h-[1px] my-5"></div>
                    )}

                    <ExpandableStages
                        has_reached_cutoff={has_reached_cutoff}
                        event={event}
                        options={options}
                        active_stage={active_stage} // use mock_stage when testing the stages
                        is_event_over={is_event_over}
                        hardcap={hardcap}
                        total_raised={total_raised}
                    />
                </div>
            )}
        </Card>
    );
};
