import React from 'react';
import { HeroSectionV2 } from '@decub8/ui';
import { useRouter } from 'next/router';

import { CONTENT } from '@src/config';
import { useAppSelector } from '@src/hooks/index';
import { IContentSection } from '@src/ts/interfaces';

import { header_height, scrollIntoView } from '../utils';

export const stats_key_to_label = {
    average_ath_roi: 'Average ATH ROI',
    total_users: `Users chosen ${CONTENT.companyName}`,
    combined_ido_fdv: 'Combined IDO FDV',
};

export const HeroSection: React.FC<{
    section: IContentSection;
    events_ref: React.RefObject<HTMLDivElement>;
}> = ({ section, events_ref }) => {
    const { user } = useAppSelector((state) => state?.auth) || {};
    const desktop_image = section.images?.find((img) => img.name === 'desktop');
    const mobile_image = section.images?.find((img) => img.name === 'mobile');
    const evm_image = section.images?.find((img) => img.name === 'evm_chains');
    const router = useRouter();

    const data = (section?.data ?? []).map(({ key, value }) => ({
        value: value,
        label: stats_key_to_label[key],
    }));

    return (
        <HeroSectionV2
            images={{
                desktop: desktop_image?.url,
                mobile: mobile_image?.url,
                evm_chains: evm_image?.url,
            }}
            buttons={[
                {
                    label: user ? 'Explore events' : 'Get started',
                    onClick: () =>
                        user
                            ? scrollIntoView(events_ref, header_height)
                            : router.push('/login'),
                },
                {
                    label: user ? 'Increase allocation' : 'Get allocation',
                    onClick: () => router.push('/staking'),
                },
                {
                    label: 'Join community',
                    onClick: () =>
                        window.open(
                            CONTENT.telegramUrl,
                            '_blank',
                            'norefferer',
                        ),
                },
            ]}
            cards_data={data}
            title={section.title}
            subtitle={section.subtitle}
        />
    );
};
